import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";

const SnackBarDuet = (props) => {
  return (
    <div>
      <Snackbar
        open={props.status.success}
        autoHideDuration={6000}
        onClose={props.closeSuccess}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={props.closeSuccess}
        >
          {props.successPrompt}
        </Alert>
      </Snackbar>
      <Snackbar
        open={props.status.failed}
        autoHideDuration={6000}
        onClose={props.closeFailed}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          onClose={props.closeFailed}
        >
          {props.failedPrompt}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBarDuet;
