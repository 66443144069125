import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { DialogContent } from "@mui/material";
import CircularProgressPopup from "./circularProgress";
import { LanguageContext } from "../context/languageContext";
import { assessmentLabels } from "../config/commonLabels";
import { useContext } from "react";

const ConfirmDialog = (props) => {
  const { language } = useContext(LanguageContext);

  return (
    <Dialog
      open={props.open}
      onClose={props.onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.details}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCloseDialog} color="primary">
          {assessmentLabels.cancel[language]}
        </Button>
        <Button onClick={props.onConfirm} color="primary">
          {assessmentLabels.confirm[language]}
        </Button>
      </DialogActions>
      {props.isFetching && <CircularProgressPopup />}
    </Dialog>
  );
};

export default ConfirmDialog;
