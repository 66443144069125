import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  footer: {
    bottom: "0",
    backgroundColor: "black",
    textAlign: "center",
    color: "white",
    width: "100%",
    height: "auto",
    paddingBlock: "1em",
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.footer}
    >
      © 2023. All Rights Reserved.
      <br />
      Powered by Talent Link
    </Typography>
  );
};

export default Footer;
