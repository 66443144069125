import React, { createContext, useEffect, useState } from "react";
import { baseBackURL } from "../config/baseUrl";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("userToken"));
  const [user, setUser] = useState(() =>
    localStorage.getItem("email") === null
      ? { email: null, id: null, isAdmin: null }
      : {
          email: localStorage.getItem("email"),
          id: localStorage.getItem("id"),
          isAdmin: localStorage.getItem("isAdmin") === "true",
        }
  );

  useEffect(() => {
    const fetchUser = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(
        baseBackURL + "/api/users/me",
        requestOptions
      );
      if (!response.ok) {
        setToken(null);
        setUser({
          email: null,
          id: null,
          isAdmin: null,
        });
      }
      localStorage.setItem("userToken", token);
    };
    fetchUser();
  }, [token]);

  return (
    <UserContext.Provider
      value={{ tokenKey: [token, setToken], userKey: [user, setUser] }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
