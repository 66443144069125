import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useNavigate } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";

// ICONS
import HelpIcon from "@material-ui/icons/Help";
import ContactsIcon from "@material-ui/icons/Contacts";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";

import { UserContext } from "../context/userContext";
import { LanguageContext } from "../context/languageContext";
import { ListItemIcon } from "@material-ui/core";
import ConfirmDialog from "../utils/confirmDialog";
import Footer from "./footer";
import {
  loginPageLabels,
  appBarLabel,
  assessmentLabels,
  logoutLabel,
} from "../config/commonLabels";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
}));

export default function ClbsAppBar(props) {
  const classes = useStyles();
  const { tokenKey, userKey } = useContext(UserContext);
  const { language } = useContext(LanguageContext);
  const [token, setToken] = tokenKey;
  const [user, setUser] = userKey;
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    left: false,
  });

  const navigate = useNavigate();

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };
  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem>
          <ListItemButton onClick={() => navigate("/home")}>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary={appBarLabel.assessment[language]} />
          </ListItemButton>
        </ListItem>
        {user.isAdmin && (
          <ListItem>
            <ListItemButton onClick={() => navigate("/adminPanel")}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary={appBarLabel.adminPanel[language]} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton onClick={() => navigate("/help")}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={assessmentLabels.help[language]} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={() => navigate("/contactUs")}>
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText primary={appBarLabel.contactUs[language]} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const handleLogout = () => {
    setToken(null);
    setUser({
      email: null,
      id: null,
      isAdmin: null,
    });
    localStorage.setItem("email", null);
    localStorage.setItem("id", null);
    localStorage.setItem("isAdmin", null);
    localStorage.setItem("currentPage", 1);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            sx={{
              height: 50,
              width: 125,
            }}
            alt="TalentLinkLogo"
            src="/Talentlink_white.png"
            onClick={() => navigate("/home")}
          />
          <Box sx={{ flexGrow: 1 }} />
          {!token && (
            <Button
              startIcon={<LoginIcon />}
              color="inherit"
              onClick={() => navigate("/login")}
            >
              {loginPageLabels.login[language]}
            </Button>
          )}
          {token && (
            <Button
              startIcon={<LogoutIcon />}
              color="inherit"
              onClick={() => setOpen(true)}
            >
              {loginPageLabels.logout[language]}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <ConfirmDialog
        open={open}
        onCloseDialog={() => setOpen(false)}
        title={logoutLabel.confirmLogout[language]}
        details={logoutLabel.confirmLogoutPrompt[language]}
        onConfirm={handleLogout}
      />
      <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
        {sideList("left")}
      </Drawer>
      <Box sx={{ minHeight: "100vh" }}>{props.children}</Box>
      <Footer />
    </div>
  );
}
