export const panelLabels = {
  title: {
    0: "Search Report Status / Download Report",
    1: "搜查測量狀況 / 下載測量報告",
    2: "搜查测量状况 / 下载测量报告",
  },
  selectGroup: {
    0: "Select Group",
    1: "選擇組別",
    2: "选择组别",
  },
  selectSortingSequence: {
    0: "Select sorting sequence",
    1: "選擇排序",
    2: "选择排序",
  },
  selectOrder: {
    0: "Select Order",
    1: "選擇次序",
    2: "选择次序",
  },
  ascending: {
    0: "Ascending",
    1: "順序",
    2: "顺序",
  },
  descending: {
    0: "Descending",
    1: "倒序",
    2: "倒序",
  },
  email: {
    0: "Email",
    1: "電郵",
    2: "电邮",
  },
  company: {
    0: "Company",
    1: "公司",
    2: "公司",
  },
  group: {
    0: "Group",
    1: "組別",
    2: "組別",
  },
  englishName: {
    0: "English Name",
    1: "英文姓名",
    2: "英文姓名",
  },
  chineseName: {
    0: "Chinese Name",
    1: "中文姓名",
    2: "中文姓名",
  },
  lastAnswerTime: {
    0: "Last Answer Time",
    1: "最後作答時間",
    2: "最后作答时间",
  },
  lastAnswerQuestion: {
    0: "Last Answer Question",
    1: "最後作答題目",
    2: "最后作答题目",
  },
  completedAssessment: {
    0: "Completed Assessment",
    1: "完成測量表",
    2: "完成测量表",
  },
  assessmentReport: {
    0: "Assessment Report",
    1: "測量表報告",
    2: "测量表报告",
  },
  downloadRawScore: {
    0: "Download Raw Scores",
    1: "下載 Raw Scores",
    2: "下载 Raw Scores",
  },
  downloadReport: {
    0: "Download Report",
    1: "下載報告",
    2: "下载报告",
  },
  reset: {
    0: "Reset",
    1: "重設",
    2: "重设",
  },
  rawScore: {
    0: "Download Multiple Raw Scores",
    1: "下載多份 Raw Scores",
    2: "下载多份 Raw Scores",
  },
  report: {
    0: "Download Multiple Reports",
    1: "下載多份報告",
    2: "下载多份报告",
  },
};

export const userDetailsLabels = {
  userInformation: {
    0: "User Information",
    1: "用戶信息",
    2: "用户信息",
  },
  userEmail: {
    0: "User Email: ",
    1: "用戶電郵：",
    2: "用户电邮：",
  },
  updateUserInfo: {
    0: "Update User Info",
    1: "更新用戶信息",
    2: "更新用户信息",
  },
  newCompany: {
    0: "New Company",
    1: "新公司",
    2: "新公司",
  },
  newPassword: {
    0: "New Password",
    1: "新密碼",
    2: "新密码",
  },
  confirmNewPassword: {
    0: "Confirm New Password",
    1: "確認新密碼",
    2: "确认新密码",
  },
  notify: {
    0: "Notify",
    1: "通知",
    2: "通知",
  },
  update: {
    0: "Update",
    1: "更新",
    2: "更新",
  },
  delete: {
    0: "Delete",
    1: "刪除",
    2: "删除",
  },
  addAsAdmin: {
    0: "Add as Admin",
    1: "添加為管理員",
    2: "添加为管理员",
  },
  removeAsAdmin: {
    0: "Remove as Admin",
    1: "刪除為管理員",
    2: "删除为管理员",
  },
  close: {
    0: "Close",
    1: "關閉",
    2: "关闭",
  },
  downloadResult: {
    0: "Download Result",
    1: "下載答案",
    2: "下载答案",
  },
  enterSamePassword: {
    0: "Please enter the same password",
    1: "請輸入相同的密碼",
    2: "请输入相同的密码",
  },
  confirmToNotify: {
    0: "to notify this user by email",
    1: "通過電子郵件通知該用戶",
    2: "通过电子邮件通知该用户",
  },
  confirmToUpdate: {
    0: "to update user details",
    1: "更新用戶詳細信息",
    2: "更新用户详细信息",
  },
  confirmToDelete: {
    0: "to delete this user",
    1: "刪除這個用戶",
    2: "删除这个用户",
  },
  notifyStatus: {
    success: {
      0: "Successfully notified user!",
      1: "成功通知用戶！",
      2: "成功通知用户！",
    },
    failed: {
      0: "Failed to notify user, please try again!",
      1: "通知用戶失敗，請重試！",
      2: "通知用户失败，请重试！",
    },
  },
  deleteStatus: {
    success: {
      0: "Successfully deleted user!",
      1: "成功刪除用戶！",
      2: "成功删除用户！",
    },
    failed: {
      0: "Failed to delete user, please try again!",
      1: "刪除用戶失敗，請重試！",
      2: "删除用户失败，请重试！",
    },
  },
  updateStatus: {
    success: {
      0: "Successfully updated user!",
      1: "成功更新用戶！",
      2: "成功更新用户！",
    },
    failed: {
      0: "Failed to update user, please try again!",
      1: "更新用戶失敗，請重試！",
      2: "更新用户失败，请重试！",
    },
  },
  addAsAdminStatus: {
    success: {
      0: "Successfully added user as admin!",
      1: "成功添加用戶為管理員！",
      2: "成功添加用户为管理员！",
    },
    failed: {
      0: "Failed to add user as admin, please try again!",
      1: "添加用戶為管理員失敗，請重試！",
      2: "添加用户为管理员失败，请重试！",
    },
  },
  removeAsAdminStatus: {
    success: {
      0: "Successfully removed user as admin!",
      1: "已成功刪除用戶為管理員！",
      2: "已成功删除用户为管理员！",
    },
    failed: {
      0: "Failed to remove user as admin, please try again!",
      1: "無法刪除用戶作為管理員，請重試！",
      2: "无法删除用户作为管理员，请重试！",
    },
  },
};
