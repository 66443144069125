import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ClbsAppBar from "../wrapper/appBar";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
} from "@material-ui/core";
import { Alert, ListItemButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { LanguageContext } from "../context/languageContext";
import { appBarLabel } from "../config/commonLabels";

const infoEmail = "info@talentlink.com.hk";
const infoPhoneNumber = "+852 93864303";
const infoWebsite = "https://www.talentlink.com.hk/about-us/";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    maxWidth: "90%",
    margin: "auto",
    marginTop: "20px",
    padding: "20px",
  },
  media: {
    height: 200,
  },
});

const copiedToClipBoard = {
  0: "Copied to clipboard!",
  1: "已復制到剪貼板",
  2: "已复制到剪贴板",
};

const learnMoreAbout = {
  0: "More About Talent Link",
  1: "瞭解更多關於臻領",
  2: "了解更多关于臻领",
};

export default function ContactUs() {
  const classes = useStyles();

  const [copied, setCopied] = useState(false);
  const { language } = useContext(LanguageContext);
  const onClickWhatsApp = async () => {
    await navigator.clipboard.writeText(infoPhoneNumber);
    setCopied(true);
  };

  return (
    <ClbsAppBar>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image="/HK-pic.jpeg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            {appBarLabel.contactUs[language]}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              {language === "0" && (
                <div>
                  <Typography variant="body1" component="p">
                    Talent Link Global Limited
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Room 1101, 11/F, Tower 1, Cheung Sha Wan Plaza,
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    833 Cheung Sha Wan Road,
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Lai Chi Kok, Kowloon, Hong Kong
                  </Typography>
                </div>
              )}
              {language === "1" && (
                <div>
                  <Typography variant="body1" component="p">
                    臻領環球有限公司
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    香港九龍荔枝角長沙灣道833號
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    長沙灣廣場1期11樓1101室
                  </Typography>
                </div>
              )}
              {language === "2" && (
                <div>
                  <Typography variant="body1" component="p">
                    臻领环球有限公司
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    香港九龙荔枝角长沙湾道833号
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    长沙湾广场1期11楼1101室
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <List disablePadding dense>
                <ListItem disableGutters disablePadding>
                  <ListItemButton
                    component="a"
                    href="mailto:info@talentlink.com.hk"
                  >
                    <ListItemIcon
                      style={{ marginLeft: "-15px", marginRight: "-20px" }}
                    >
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary={infoEmail} />
                  </ListItemButton>
                </ListItem>
                <ListItem disableGutters disablePadding>
                  <ListItemButton onClick={onClickWhatsApp}>
                    <ListItemIcon
                      style={{ marginLeft: "-15px", marginRight: "-20px" }}
                    >
                      <WhatsAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={infoPhoneNumber} />
                  </ListItemButton>
                </ListItem>
                <ListItem disableGutters disablePadding>
                  <ListItemButton
                    component="a"
                    href={infoWebsite}
                    target="_blank"
                  >
                    <ListItemIcon
                      style={{ marginLeft: "-15px", marginRight: "-20px" }}
                    >
                      <LightbulbIcon />
                    </ListItemIcon>
                    <ListItemText primary={learnMoreAbout[language]} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Snackbar
        open={copied}
        autoHideDuration={6000}
        onClose={() => setCopied(false)}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={() => setCopied(false)}
        >
          {copiedToClipBoard[language]}
        </Alert>
      </Snackbar>
    </ClbsAppBar>
  );
}
