import React, { useState, createContext, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = (props) => {
  const [language, setLanguage] = useState(() =>
    localStorage.getItem("language") === null
      ? "0"
      : localStorage.getItem("language")
  );

  useEffect(() => {
    // checking language is correct when it is updated
    if (!["0", "1", "2"].includes(language)) {
      setLanguage("0");
    }
  }, [language]);

  const changeLanguage = (event) => {
    localStorage.setItem("language", event.target.value);
    setLanguage(event.target.value);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
};
