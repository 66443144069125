import React, { useContext } from "react";

import { UserContext } from "./context/userContext";
import { Route, Routes, Navigate } from "react-router-dom";
import routes from "./config/routes";

const App = () => {
  const { tokenKey, userKey } = useContext(UserContext);
  const [token] = tokenKey;
  const [user] = userKey;

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            !token ? (
              <Navigate to="/login" exact />
            ) : user.isAdmin ? (
              <Navigate to="/adminPanel" exact />
            ) : (
              <Navigate to="/home" exact />
            )
          }
        />
        {routes.map((route, index) => (
          <Route
            key={index}
            exact
            element={
              route.protected ? (
                !token ? (
                  <Navigate to="/" />
                ) : route.adminPrivileged ? (
                  !user.isAdmin ? (
                    <Navigate to="/" />
                  ) : (
                    route.component
                  )
                ) : (
                  route.component
                )
              ) : (
                route.component
              )
            }
            path={route.path}
          />
        ))}
        <Route path="/*" element={<Navigate to="/" />}></Route>
      </Routes>
    </>
  );
};

export default App;
