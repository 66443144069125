import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ClbsAppBar from "../wrapper/appBar";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    maxWidth: "70%",
    margin: "auto",
    marginTop: "20px",
    padding: "20px",
  },
  media: {
    height: 200,
  },
});

export default function ContactUs() {
  const classes = useStyles();

  return (
    <ClbsAppBar>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image="/HK-pic.jpeg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            FAQ
          </Typography>
        </CardContent>
      </Card>
    </ClbsAppBar>
  );
}
