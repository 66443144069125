import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Button,
  FormLabel,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Checkbox,
} from "@material-ui/core";
import DoneIcon from "@mui/icons-material/Done";
import { format } from "date-fns";
import JSZip from "jszip";
import html2pdf from "html2pdf.js";

import ClbsAppBar from "../wrapper/appBar";
import CircularProgress from "@mui/material/CircularProgress";
import { baseBackURL } from "../config/baseUrl";
import { UserContext } from "../context/userContext";
import { LanguageContext } from "../context/languageContext";
import { panelLabels } from "../config/adminPanelLabels";
import ReportPage from "./clbsReport";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    maxWidth: "90%",
    margin: "auto",
    marginBlock: "20px",
    padding: "20px",
  },
  title: {
    fontSize: 36,
    textAlign: "center",
  },
});

const AdminPanel = () => {
  const { tokenKey } = useContext(UserContext);
  const { language } = useContext(LanguageContext);
  const [token] = tokenKey;
  const [permission, setPermission] = useState(true);

  const classes = useStyles();

  const [fetchingGetAllUser, setFetchingGetAllUser] = useState(false);
  const [filter, setFilter] = useState({
    group: "",
    sortingSequence: "",
    ascending: "",
  });

  const [userData, setUserData] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const getAllUser = async () => {
      setFetchingGetAllUser(true);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const response = await fetch(
        baseBackURL + "/api/users/getall",
        requestOptions
      );
      const data = await response.json();
      setFetchingGetAllUser(false);
      if (!response.ok) {
        setPermission(false);
      } else {
        setPermission(true);
        setUserData(data);
        const groupSet = new Set();
        for (const userData of data) {
          groupSet.add(userData.group);
        }
        setGroups(Array.from(groupSet));
        const tmpMap = {};
        for (const userData of data) {
          if (!tmpMap.hasOwnProperty(userData.group)) {
            tmpMap[userData.group] = [userData];
          } else {
            tmpMap[userData.group].push(userData);
          }
        }
        const userEmails = [];
        const groupData = [];
        Object.keys(tmpMap).forEach((key, idx) => {
          groupData.push({
            Group: key,
            Users: tmpMap[key],
          });
          for (const usr of tmpMap[key]) {
            userEmails.push(usr.email);
          }
        });
      }
    };
    getAllUser();
  }, [token]);

  return (
    <ClbsAppBar>
      {!permission && <PermissionDenied />}
      {permission && (
        <Card className={classes.card}>
          <Typography
            variant="h4"
            gutterBottom
            style={{ textDecoration: "underline", fontWeight: "bold" }}
          >
            {panelLabels.title[language]}
          </Typography>
          <br />
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormLabel component="legend">
                <Typography
                  variant="h6"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  {panelLabels.selectGroup[language]}
                </Typography>
              </FormLabel>
              <FormControl style={{ width: "35%" }}>
                <InputLabel id="group-label">
                  {panelLabels.group[language]}
                </InputLabel>
                <Select
                  labelId="group-label"
                  id="group-select"
                  value={filter.group}
                  onChange={(e) =>
                    setFilter((prevState) => ({
                      ...prevState,
                      group: e.target.value,
                    }))
                  }
                >
                  {groups.map((group, idx) => (
                    <MenuItem key={idx} value={group}>
                      {group}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormLabel component="legend">
                <Typography
                  variant="h6"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  {panelLabels.selectSortingSequence[language]}
                </Typography>
              </FormLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Sorting Sequence"
                  name="sequence"
                  value={filter.sortingSequence}
                  onChange={(e) =>
                    setFilter((prevState) => ({
                      ...prevState,
                      sortingSequence: e.target.value,
                    }))
                  }
                  row
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={panelLabels.englishName[language]}
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={panelLabels.lastAnswerTime[language]}
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={panelLabels.group[language]}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item>
              <FormLabel component="legend">
                <Typography
                  variant="h6"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  {panelLabels.selectOrder[language]}
                </Typography>
              </FormLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="Sort Order"
                  name="order"
                  value={filter.ascending}
                  onChange={(e) =>
                    setFilter((prevState) => ({
                      ...prevState,
                      ascending: e.target.value,
                    }))
                  }
                >
                  <FormControlLabel
                    value="ascending"
                    control={<Radio />}
                    label={panelLabels.ascending[language]}
                  />
                  <FormControlLabel
                    value="descending"
                    control={<Radio />}
                    label={panelLabels.descending[language]}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  setFilter({
                    group: "",
                    sortingSequence: "",
                    ascending: "",
                  })
                }
              >
                {panelLabels.reset[language]}
              </Button>
            </Grid>
          </Grid>
          <hr />
          <UserTable
            userData={userData}
            filter={filter}
            language={language}
            token={token}
          />
        </Card>
      )}
      {fetchingGetAllUser && <CircularProgressPopup />}
    </ClbsAppBar>
  );
};

const UserTable = ({ userData, filter, language, token }) => {
  const filteredUser = userData.filter(
    (user) => filter.group === "" || user.group === filter.group
  );
  const emailList = filteredUser.map((d) => d.email);
  const emailToDetails = {};
  for (const userData of filteredUser) {
    emailToDetails[userData.email] = {
      english_name: userData.english_name,
      chinese_name: userData.chinese_name,
    };
  }
  const [checkAllScore, setCheckAllScore] = useState(false);
  const [checkAllReport, setCheckAllReport] = useState(false);
  const [selectedUserRawScore, setSelectedUserRawScore] = useState({});
  const [selectedUserReport, setSelectedUserReport] = useState({});
  const [selectedUser, setSelectedUSer] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [reportScore, setReportScore] = useState({});

  const fetchScoreBlob = async (email) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify([email]),
    };
    const response = await fetch(
      baseBackURL + "/api/assessment/clbs/download",
      requestOptions
    );
    return await response.blob();
  };

  const handleDownloadScore = async (email) => {
    const blob = await fetchScoreBlob(email);
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "result.csv");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleDownloadMultipleScore = async (emails) => {
    if (!(emails.length > 0)) {
      return;
    }
    const emailsToBlobs = {};
    for (const email of emails) {
      const blob = await fetchScoreBlob(email);
      emailsToBlobs[email] = blob;
    }
    const zip = new JSZip();
    for (const email in emailsToBlobs) {
      zip.file(`result-${email}.csv`, emailsToBlobs[email]);
    }
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipUrl = URL.createObjectURL(zipBlob);
    const link = document.createElement("a");
    link.href = zipUrl;
    link.setAttribute("download", "results.zip");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(zipUrl);
  };

  const handleDownloadMultipleReports = async (emails) => {
    if (!(emails.length > 0)) {
      return;
    }
    let users = [];
    for (const email of emails) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch(
        baseBackURL + `/api/assessment/clbs/reportScore?email=${email}`,
        requestOptions
      );
      const user = {
        email: email,
        userName: emailToDetails[email]["english_name"],
        userId: email,
        reportScore: await res.json(),
      };
      users.push(user);
    }
    setSelectedUsers(users);
    await sleep(100);
    const zip = new JSZip();
    for (const user of users) {
      const opt = {
        filename: `${user.email}-report.pdf`,
        margin: 0,
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      };
      const element = document.getElementById(`clbs-report-${user.email}`);
      const pdf = await html2pdf()
        .set(opt)
        .from(element)
        .outputPdf("blob", `${user.email}-report.pdf`);
      zip.file(`${user.email}-report.pdf`, pdf);
    }
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipUrl = URL.createObjectURL(zipBlob);
    const link = document.createElement("a");
    link.href = zipUrl;
    link.setAttribute("download", "report.zip");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(zipUrl);
  };

  const handleSelectAllRawScore = () => {
    setCheckAllScore(!checkAllScore);
    for (const user of filteredUser) {
      setSelectedUserRawScore((prev) => ({
        ...prev,
        [user.email]: !checkAllScore,
      }));
    }
    for (const email in selectedUserRawScore) {
      if (!emailList.includes(email)) {
        setSelectedUserRawScore((prev) => ({
          ...prev,
          [email]: false,
        }));
      }
    }
  };

  const handleSelectAllReport = () => {
    setCheckAllReport(!checkAllReport);
    for (const user of filteredUser) {
      setSelectedUserReport((prev) => ({
        ...prev,
        [user.email]: !checkAllReport,
      }));
    }
    for (const email in selectedUserReport) {
      if (!emailList.includes(email)) {
        setSelectedUserReport((prev) => ({
          ...prev,
          [email]: false,
        }));
      }
    }
  };

  const handleDownloadReport = async (user) => {
    setSelectedUSer(user);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const res = await fetch(
      baseBackURL + `/api/assessment/clbs/reportScore?email=${user.email}`,
      requestOptions
    );
    const score = await res.json();
    setReportScore(score);
    // FETCH USER SCORE HERE
    const element = document.getElementById(`clbs-report-${user.email}`);
    const opt = {
      filename: `${user.email}-report.pdf`,
      margin: 0,
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
      },
    };
    await sleep(100);
    html2pdf().set(opt).from(element).save();
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{panelLabels.email[language]}</TableCell>
            <TableCell>{panelLabels.group[language]}</TableCell>
            <TableCell>{panelLabels.englishName[language]}</TableCell>
            <TableCell>{panelLabels.chineseName[language]}</TableCell>
            <TableCell>{panelLabels.lastAnswerQuestion[language]}</TableCell>
            <TableCell>{panelLabels.completedAssessment[language]}</TableCell>
            <TableCell>{panelLabels.lastAnswerTime[language]}</TableCell>
            <TableCell>{panelLabels.assessmentReport[language]}</TableCell>
            <TableCell>
              <div>
                {panelLabels.rawScore[language]}
                <Checkbox
                  checked={checkAllScore}
                  onChange={handleSelectAllRawScore}
                />
              </div>
            </TableCell>
            <TableCell>
              <div>
                {panelLabels.report[language]}
                <Checkbox
                  checked={checkAllReport}
                  onChange={handleSelectAllReport}
                />
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUser
            .sort((a, b) => {
              if (
                filter.sortingSequence === "0" &&
                a.english_name !== b.english_name
              ) {
                if (filter.ascending === "ascending") {
                  return a.english_name.localeCompare(b.english_name);
                } else {
                  return b.english_name.localeCompare(a.english_name);
                }
              } else if (
                filter.sortingSequence === "1" &&
                a.clbs &&
                b.clbs &&
                a.clbs.last_answered_time &&
                b.clbs.last_answered_time
              ) {
                if (filter.ascending === "ascending") {
                  return (
                    new Date(a.clbs.last_answered_time) -
                    new Date(b.clbs.last_answered_time)
                  );
                } else {
                  return (
                    new Date(b.clbs.last_answered_time) -
                    new Date(a.clbs.last_answered_time)
                  );
                }
              } else {
                if (filter.ascending === "ascending") {
                  return a.group.localeCompare(b.group);
                } else {
                  return b.group.localeCompare(a.group);
                }
              }
            })
            .map((user, idx) => (
              <TableRow
                key={user.id}
                style={{
                  backgroundColor: idx % 2 === 0 ? "#FFFFFF" : "#F5F5F5",
                }}
              >
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.group}</TableCell>
                <TableCell>{user.english_name}</TableCell>
                <TableCell>{user.chinese_name}</TableCell>
                <TableCell>
                  {user.clbs ? user.clbs.last_answered_question : ""}
                </TableCell>
                <TableCell>
                  {user.clbs ? user.clbs.is_completed ? <DoneIcon /> : "" : ""}
                </TableCell>
                <TableCell>
                  {user.clbs
                    ? format(
                        new Date(user.clbs.last_answered_time),
                        "yyyy-MM-dd HH:mm:ss"
                      )
                    : ""}
                </TableCell>
                <TableCell>
                  <Link
                    style={{ marginRight: "5px" }}
                    onClick={() => handleDownloadReport(user)}
                  >
                    report_pdf
                  </Link>
                  <Link
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      handleDownloadScore(user.email);
                    }}
                  >
                    score_csv
                  </Link>
                </TableCell>
                <TableCell>
                  <Checkbox
                    onClick={() =>
                      setSelectedUserRawScore((prev) => ({
                        ...prev,
                        [user.email]: !prev[user.email],
                      }))
                    }
                    checked={selectedUserRawScore[user.email] === true}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    onClick={() =>
                      setSelectedUserReport((prev) => ({
                        ...prev,
                        [user.email]: !prev[user.email],
                      }))
                    }
                    checked={selectedUserReport[user.email] === true}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div style={{ display: "none" }}>
        <ReportPage
          email={selectedUser.email}
          userName={selectedUser.english_name}
          userId={selectedUser.email}
          score={reportScore}
        />
      </div>
      <div style={{ display: "none" }}>
        {selectedUsers.map((user, key) => (
          <ReportPage
            email={user.email}
            userName={user.english_name}
            userId={user.email}
            score={user.reportScore}
          />
        ))}
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleDownloadMultipleScore(
            Object.entries(selectedUserRawScore)
              .filter(([key, value]) => value === true)
              .map(([key, value]) => key)
          )
        }
        style={{ marginTop: "10px", marginRight: "20px" }}
      >
        {panelLabels.downloadRawScore[language]}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleDownloadMultipleReports(
            Object.entries(selectedUserReport)
              .filter(([key, value]) => value === true)
              .map(([key, value]) => key)
          )
        }
        style={{ marginTop: "10px" }}
      >
        {panelLabels.downloadReport[language]}
      </Button>
    </TableContainer>
  );
};

const PermissionDenied = () => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} color="textPrimary" variant="h5">
          Oops!
        </Typography>
        <Typography style={{ textAlign: "center" }} color="textSecondary">
          Sorry, you do not have admin permission to view this page
        </Typography>
      </CardContent>
    </Card>
  );
};

const CircularProgressPopup = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default AdminPanel;
