import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import ClbsAppBar from "../wrapper/appBar";
import ClbsLabel from "../config/assessmentLabel";
import { LanguageContext } from "../context/languageContext";
import { assessmentLabels } from "../config/commonLabels";

const useStyles = makeStyles({
  card: {
    margin: "20px",
    width: "auto",
    padding: "20px",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SleepQualityQuestionnaire() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  return (
    <ClbsAppBar>
      <Grid container justifyContent="center">
        <Card className={classes.card}>
          <CardContent>
            <ClbsLabel />
          </CardContent>
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/clbsQuestions")}
              style={{ marginInline: "0.5em" }}
            >
              {assessmentLabels.start[language]}
            </Button>
            <Button variant="outlined" style={{ marginInline: "0.5em" }}>
              {assessmentLabels.help[language]}
            </Button>
          </Grid>
        </Card>
      </Grid>
    </ClbsAppBar>
  );
}
