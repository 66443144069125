//0: eng, 1: traditional chinese, 2: simplified chinese
const answerLabel = {
  0: {
    1: "1 Almost Never",
    2: "2 Very Infrequent",
    3: "3 Infrequent",
    4: "4 Sometimes",
    5: "5 Frequent",
    6: "6 Very frequent",
    7: "7 Almost Always",
    0: "0 Not Applicable",
  },
  1: {
    1: "1 幾乎沒有",
    2: "2 非常少",
    3: "3 少",
    4: "4 間中",
    5: "5 頻密",
    6: "6 非常頻密",
    7: "7 幾乎常常",
    0: "0 不適用",
  },
  2: {
    1: "1 几乎没有",
    2: "2 非常少",
    3: "3 少",
    4: "4 間中",
    5: "5 频密",
    6: "6 非常频密",
    7: "7 几乎常常",
    0: "0 不适用",
  },
};

module.exports = {
  answerLabel,
};
