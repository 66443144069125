import Login from "../components/login";
import Home from "../components/home";
import ClbsQuestions from "../components/clbs_questions";
import ContactUs from "../components/contactUs";
import AdminPanel from "../components/adminPanel";
import Help from "../components/help";

const routes = [
  {
    path: "/login",
    component: <Login />,
    protected: false,
    adminPrivileged: false,
  },
  {
    path: "/home",
    component: <Home />,
    protected: true,
    adminPrivileged: false,
  },
  {
    path: "/clbsQuestions",
    component: <ClbsQuestions />,
    protected: true,
    adminPrivileged: false,
  },
  {
    path: "/contactUs",
    component: <ContactUs />,
    protected: false,
    adminPrivileged: false,
  },
  {
    path: "/adminPanel",
    component: <AdminPanel />,
    protected: true,
    adminPrivileged: false,
  },
  {
    path: "/help",
    component: <Help />,
    protected: false,
    adminPrivileged: false,
  },
];

export default routes;
