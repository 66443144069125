import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

const promptLabel = {
  0: "Please provide the following demographic information which will be collected and strictly limited to an aggregated analysis:",
  1: "請提供以下的個人資料，所收集的資料僅用作綜合分析。",
  2: "请提供以下的个人资料，所收集的资料仅用作综合分析。",
};

const genderLabel = {
  0: "Gender",
  1: "性别",
  2: "性别",
};
const genderChoices = {
  0: ["Male", "Female", "Prefer not to say", "Other"],
  1: ["男", "女", "不希望回答", "其他"],
  2: ["男", "女", "不希望回答", "其他"],
};

const ageLabel = {
  0: "Age Group",
  1: "年齡組別",
  2: "年龄组别",
};

const professionLabel = {
  0: "Profession/Occupation",
  1: "專業/職業",
  2: "专业/职业",
};

const professionChoices = {
  0: [
    "Accounting",
    "Administrative",
    "Architecture",
    "Banking & Finance",
    "Civil Service",
    "Customer Service",
    "Consulting",
    "Education",
    "Engineering",
    "Food & Beverage",
    "Human Resources",
    "Information Technology",
    "Insurance",
    "Legal",
    "General Mangagement",
    "Healthcare",
    "Marketing",
    "Purchase",
    "Real Estate & Property Mangement",
    "Research & Development",
    "Sales",
    "Social Welfare",
    "Trading",
    "Transport & Logistics",
    "Other",
  ],
  1: [
    "會計",
    "行政",
    "建築",
    "銀行與金融",
    "公務員",
    "顧客服務",
    "顧問",
    "教育",
    "工程",
    "餐飲",
    "人力資源",
    "資訊科技",
    "保險",
    "法律",
    "綜合管理",
    "醫療",
    "市場營銷",
    "採購",
    "房地產與物業管理",
    "研發",
    "銷售",
    "社會福利",
    "貿易",
    "運輸與物流",
    "其他",
  ],
  2: [
    "会计",
    "行政",
    "建筑",
    "银行与金融",
    "公务员",
    "顾客服务",
    "顾问",
    "教育",
    "工程",
    "餐饮",
    "人力资源",
    "资讯科技",
    "保险",
    "法律",
    "综合管理",
    "医疗",
    "市场营销",
    "采购",
    "房地产与物业管理",
    "研发",
    "销售",
    "社会福利",
    "贸易",
    "运输与物流",
    "其他",
  ],
};

const managementExperienceLabel = {
  0: "Years of Management Experience",
  1: "管理經驗的年資",
  2: "管理经验的年资",
};

const pleaseSpecifyLabel = {
  0: "Please specify:",
  1: "請註明:",
  2: "请注明:",
};

export default function ClbsDemographics(props) {
  const [age, setAge] = useState("");
  const [otherGender, setOtherGender] = useState(false);
  const [gender, setGender] = useState("");
  const [profession, setProfession] = useState("");
  const [otherProfession, setOtherProfession] = useState(false);

  const onAgeChange = (e) => {
    setAge(e.target.value);
    props.onUpdateAnswer(e, "Age");
  };

  const onGenderChange = (e) => {
    setGender(e.target.value);
    props.onUpdateAnswer(e, "Gender");

    // last element is others
    if (e.target.value === genderChoices[props.language].slice(-1)[0]) {
      setOtherGender(true);
    } else {
      setOtherGender(false);
    }
  };

  const onProfessionChange = (e) => {
    setProfession(e.target.value);
    props.onUpdateAnswer(e, "Profession");

    // last element is others
    if (e.target.value === professionChoices[props.language].slice(-1)[0]) {
      setOtherProfession(true);
    } else {
      setOtherProfession(false);
    }
  };

  return (
    <div>
      <List>
        <ListItem>
          <ListItemText>
            <Typography variant="body1">
              {promptLabel[props.language]}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <FormControl variant="standard" style={{ width: "75%" }}>
            <InputLabel>{genderLabel[props.language]}</InputLabel>
            <Select
              value={gender}
              onChange={onGenderChange}
              label={genderLabel[props.language]}
            >
              {genderChoices[props.language].map((gender, index) => (
                <MenuItem key={index} value={gender}>
                  {gender}
                </MenuItem>
              ))}
            </Select>
            {otherGender && (
              <TextField
                label={pleaseSpecifyLabel[props.language]}
                onChange={(e) => props.onUpdateAnswer(e, "Gender")}
              />
            )}
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard" style={{ width: "75%" }}>
            <InputLabel>{ageLabel[props.language]}</InputLabel>
            <Select
              value={age}
              onChange={onAgeChange}
              label={ageLabel[props.language]}
            >
              <MenuItem value="21-25">21-25</MenuItem>
              <MenuItem value="26-30">26-30</MenuItem>
              <MenuItem value="31-35">31-35</MenuItem>
              <MenuItem value="36-40">36-40</MenuItem>
              <MenuItem value="41-46">41-46</MenuItem>
              <MenuItem value="46-50">46-50</MenuItem>
              <MenuItem value="51-55">51-55</MenuItem>
              <MenuItem value="56+">56+</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl variant="standard" style={{ width: "75%" }}>
            <InputLabel>{professionLabel[props.language]}</InputLabel>
            <Select
              value={profession}
              onChange={onProfessionChange}
              label={professionLabel[props.language]}
            >
              {professionChoices[props.language].map((gender, index) => (
                <MenuItem key={index} value={gender}>
                  {gender}
                </MenuItem>
              ))}
            </Select>
            {otherProfession && (
              <TextField
                label={pleaseSpecifyLabel[props.language]}
                onChange={(e) => props.onUpdateAnswer(e, "Profession")}
              />
            )}
          </FormControl>
        </ListItem>
        <ListItem>
          <TextField
            style={{ width: "75%" }}
            label={managementExperienceLabel[props.language]}
            onChange={(e) =>
              props.onUpdateAnswer(e, "yearsManagementExperience")
            }
          />
        </ListItem>
      </List>
    </div>
  );
}
