import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@mui/material";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../context/userContext";
import { LanguageContext } from "../context/languageContext";
import ClbsAppBar from "../wrapper/appBar";
import { baseBackURL } from "../config/baseUrl";
import { loginPageLabels } from "../config/commonLabels";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Link,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: "auto",
    marginTop: "20px",
    padding: "20px",
    maxWidth: "500px",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkedTerms, setCheckedTerms] = useState(false);
  const { language, changeLanguage } = useContext(LanguageContext);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { tokenKey, userKey } = useContext(UserContext);
  const [, setToken] = tokenKey;
  const [, setUser] = userKey;

  const navigate = useNavigate();

  const submitLogin = async () => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };

    const response = await fetch(baseBackURL + "/api/token/", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setLoginError(true);
    } else {
      // must setUser before setToken, due to onEffect from UserContext
      setUser({
        email: data.email,
        id: data.id,
        isAdmin: data.is_admin,
      });
      localStorage.setItem("email", data.email);
      localStorage.setItem("id", data.id);
      localStorage.setItem("isAdmin", data.is_admin);
      setToken(data.access_token);
      navigate("/");
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setLoginError(true);
    } else {
      submitLogin();
    }
  };

  const handleCheckTerms = (e) => {
    setCheckedTerms(e.target.checked);
  };

  return (
    <ClbsAppBar>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h4">{loginPageLabels.clbs[language]}</Typography>
          <Grid container direction="row" alignItems="center">
            <Grid item>{<h2>{loginPageLabels.login[language]}</h2>}</Grid>
            <Grid item>{<AccountCircleIcon />}</Grid>
          </Grid>
          {loginError && (
            <TextField
              error
              id="standard-basic"
              label={loginPageLabels.email[language]}
              helperText="Incorrect email / password"
              margin="normal"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
          {!loginError && (
            <TextField
              id="standard-basic"
              label={loginPageLabels.email[language]}
              margin="normal"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
          <br />
          {loginError && (
            <TextField
              error
              id="standard-password-input"
              label={loginPageLabels.password[language]}
              type="password"
              autoComplete="current-password"
              helperText="Incorrect email / password"
              margin="normal"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          {!loginError && (
            <TextField
              id="standard-password-input"
              label={loginPageLabels.password[language]}
              type="password"
              autoComplete="current-password"
              margin="normal"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          <Box sx={{ marginTop: "20px" }}>
            <InputLabel id="language-select-label">
              {loginPageLabels.language[language]}
            </InputLabel>
            <Select
              labelId="language-select-label"
              id="language-select"
              value={language}
              onChange={changeLanguage}
              autoWidth
            >
              <MenuItem value={"0"}>English</MenuItem>
              <MenuItem value={"1"}>繁體中文</MenuItem>
              <MenuItem value={"2"}>简体中文</MenuItem>
            </Select>
          </Box>
          <br />
          <Typography color="textSecondary">
            {loginPageLabels.privacy[language]}{" "}
            <Link
              href="https://www.talentlink.com.hk/privacy-policy/"
              target="_blank"
            >
              {loginPageLabels.policy[language]}
            </Link>
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckTerms} />}
              label={
                <Typography color="textSecondary">
                  {loginPageLabels.terms[0][language]}
                  <Link
                    href="https://www.talentlink.com.hk/terms-and-conditions/"
                    target="_blank"
                  >
                    {loginPageLabels.conditions[language]}
                  </Link>
                  {loginPageLabels.terms[1][language]}
                </Typography>
              }
            />
          </FormGroup>
          <br />
          <ListItem style={{ padding: 0 }}>
            {checkedTerms && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {loginPageLabels.login[language]}
              </Button>
            )}
            {!checkedTerms && (
              <Button
                disabled
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {loginPageLabels.login[language]}
              </Button>
            )}
            {loading && <CircularProgress sx={{ mx: 2 }} />}
          </ListItem>
        </CardContent>
      </Card>
    </ClbsAppBar>
  );
}
