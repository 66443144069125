export const headerLabels = {
  clbsTitle: {
    0: "Corporate Leadership Behavior Scale",
    1: "華人領導行為量表",
    2: "华人领导行为量表",
  },
  report: {
    0: "Self-Assessment Report",
    1: "自評報告",
    2: "自评报告",
  },
  reportFor: {
    0: "Report for",
    1: "報告人",
    2: "报告人",
  },
  id: {
    0: "ID",
    1: "編號",
    2: "编号",
  },
  date: {
    0: "Date",
    1: "日期",
    2: "日期",
  },
};

export const aboutCLBSLabels = {
  title: {
    0: "About CLBS",
    1: "關於CLBS",
    2: "关于CLBS",
  },
  description: {
    0: "This report summarizes your self-assessment of the leadership role behaviors you have exhibited under competing demands in the workplace. The report is based on the Corporate Leadership Behavior Scale (CLBS) which measures five core leadership qualities crucial to sustainable leadership and organization effectiveness.",
    1: "此報告為自我評估，總結了您在工作場所面對競爭需求時所表現的領導行為。此報告基於華人領導行為量表[CLBS]，該量表衡量對永續領導力和組織有效性至關重要的五項核心領導素質。",
    2: "此报告为自我评估，总结了您在工作场所面对竞争需求时所表现的领导行为。此报告基于华人领导行为量表[CLBS]，该量表衡量对永续领导力和组织有效性至关重要的五项核心领导素质。",
  },
  leadershipQuality: {
    0: "Leadership Quality",
    1: "領導素質",
    2: "领导素质",
  },
  roleBehaviour: {
    0: "Role Behaviour",
    1: "領導角色行為",
    2: "领导角色行为",
  },
  leadingChange: {
    0: "Leading Change",
    1: "引領變革",
    2: "引领变革",
  },
  leadingChangeBehaviour: {
    0: "Leadership capacity to act the Innovator and Negotiator roles.",
    1: "扮演創新者和談判者的角色。",
    2: "扮演创新者和谈判者的角色。",
  },
  producingResults: {
    0: "Producing Results",
    1: "產生結果",
    2: "产生结果",
  },
  producingResultsBehaviour: {
    0: "Leadership capacity to act the Producer and Director roles.",
    1: "扮演生產者和總監的角色。",
    2: "扮演生产者和总监的角色。",
  },
  managingProcess: {
    0: "Managing Processes",
    1: "管理流程",
    2: "管理流程",
  },
  managingProcessBehaviour: {
    0: "Leadership capacity to act the Coordinator and Monitor roles.",
    1: "扮演協調員和監督角色。",
    2: "扮演协调员和监督角色。",
  },
  relatingToPeople: {
    0: "Relating to People",
    1: "與人聯繫",
    2: "与人联系",
  },
  relatingToPeopleBehaviour: {
    0: "Leadership capacity to act the Facilitator and Mentor roles.",
    1: "扮演促進者和導師的角色。",
    2: "扮演促进者和导师的角色。",
  },
  exhibitingMoralBehaviour: {
    0: "Exhibiting Moral Behaviour",
    1: "展示道德行為",
    2: "展示道德行为",
  },
  exhibitingMoralBehaviourBehaviour: {
    0: "Leadership capacity to act the Role Model and Impartial Judge roles.",
    1: "做好榜樣和扮演公平法官的角色。",
    2: "做好榜样和扮演公平法官的角色。",
  },
};

export const aboutReportLabels = {
  about: {
    0: "About This Report",
    1: "關於此報告",
    2: "关于此报告",
  },
  pt1: {
    0: "The scores of leadership role behaviors are standardized scores based on a national group of senior professionals and managers in Greater China.",
    1: "領導角色行為的評分是根據大中華區高級專業人員和管理人員群體的標準化分數制定。",
    2: "领导角色行为的评分是根据大中华区高级专业人员和管理人员群体的标准化分数制定。",
  },
  pt2: {
    0: "The standardized scores fall in the range of 0 to 10 to indicate your self-perception on how frequently you have exhibited leadership role behaviors in the past 12 months, as compared to the reference group.",
    1: "標準化分數 (STENS) 範圍從 0 到 10，與參照組相比，反映您在過去12個月中表現出領導角色行為的頻率。",
    2: "标准化分数 (STENS) 范围从 0 到 10，与参照组相比，反映您在过去12个月中表现出领导角色行为的频率。",
  },
  pt3: {
    0: "The tendency to exhibit different leadership role behaviors may relate to various factors, including an individual's motives and personality, interpersonal dynamics, competing work role demands,and cultures of the workplace.",
    1: "自身表現出不同領導角色行為可能與多種因素有關，包括個人的動機和個性、人際關係、工作角色要求和工作場所文化。",
    2: "自身表现出不同领导角色行为可能与多种因素有关，包括个人的动机和个性、人际关系、工作角色要求和工作场所文化。",
  },
  pt4: {
    0: "You are encouraged to reflect on which asepct(s) of the leadership role behaviors you want to leverage and develop further of priority and in context.",
    1: "CLBS 自我評估的目的是提高您作為領導者的自我意識。我們鼓勵您反思不同領導行為的優先順序， 並根據具體情況作進一步的發展計劃。",
    2: "CLBS 自我评估的目的是提高您作为领导者的自我意识。我们鼓励您反思不同领导行为的优先顺序， 并根据具体情况作进一步的发展计划。",
  },
  pt5: {
    0: "All information collected from you is only for your self-reference and/or research purposes anonymously unless otherwise specified. Personal information will not be shared with a third party without your prior consent.",
    1: "除非另有說明，從自我評估中收集的所有資訊僅用於您的自我參考和/或匿名研究。未經您事先同 意，您的個人資訊不會透露予第三方。",
    2: "除非另有说明，从自我评估中收集的所有资讯仅用于您的自我参考和/或匿名研究。未经您事先同 意，您的个人资讯不会透露予第三方。",
  },
  pt6: {
    0: "Should you have any further inquiries about the interpretation of the report, please contact us at info@talentlink.com.hk",
    1: "如您對解讀報告有任何疑問，可電郵致 info@talentlink.com.hk 與我們聯絡。",
    2: "如您对解读报告有任何疑问，可电邮致 info@talentlink.com.hk 与我们联络。",
  },
};

export const reportLabels = {
  title: {
    0: "Self-Assessment Results Summary",
    1: "自評結果",
    2: "自评结果",
  },
  behaviour: {
    0: "Row Behaviour",
    1: "領導角色行為",
    2: "领导角色行为",
  },
  lowFreq: {
    0: "Low Frequency",
    1: "低頻率",
    2: "低频率",
  },
  highFreq: {
    0: "High Frequency",
    1: "高頻率",
    2: "高频率",
  },
  stenScore: {
    0: "Standardized Scores STENS",
    1: "分數",
    2: "分数",
  },
  innovator: {
    0: "Innovator",
    1: "創新者",
    2: "创新者",
  },
  innovatorLowDescription: {
    0: "Infrequently facilitates change and transformation of the unit; Rarely emphasizes creativity and vision; unlikely to attend to potential opportunities and emerging trends.",
    1: "不常促進工作單位中的變更和改造;很少強調創造力和遠見;不太可能關注潛在的機會和新興趨勢。",
    2: "不常促进工作单位中的变更和改造;很少强调创造力和远见;不太可能关注潜在的机会和新兴趋势。",
  },
  innovatorHighDescription: {
    0: "Facilitates change and transformation of the unit; emphasizes creativity and vision; seizes potential opportunities and anticipates emerging trends.",
    1: "促進工作單位中的變革和轉型;強調創造力和遠見;抓住潛在機會並預測新興趨勢。",
    2: "促进工作单位中的变革和转型;强调创造力和远见;抓住潜在机会并预测新兴趋势。",
  },
  negotiator: {
    0: "Negotiator",
    1: "談判者",
    2: "谈判者",
  },
  negotiatorLowDescription: {
    0: "Rarely influences and persuades others; unlikely to get involved in resource acquisition for the unit; infrequently gets involved in networking, negotiation, and representation.",
    1: "很少影響和說服別人;不太可能參與爭取工作單位的資源取得;很少參與建立聯繫、談判和代表。",
    2: "很少影响和说服别人;不太可能参与争取工作单位的资源取得;很少参与建立联系、谈判和代表。",
  },
  negotiatorHighDescription: {
    0: "Influences and persuades others with own ideas; acquires resources for the growth of the unit; participates in networking, negotiation, and representing the unit.",
    1: "用自己的想法影響和說服他人;為工作單位的發展爭取資源;代表工作單位與他人建立聯繫、談判。",
    2: "用自己的想法影响和说服他人;为工作单位的发展争取资源;代表工作单位与他人建立联系、谈判。",
  },
  producer: {
    0: "Producer",
    1: "生產者",
    2: "生产者",
  },
  producerLowDescription: {
    0: "Unlikely to show a sense of urgency in actions for accomplishing goals;",
    1: "不太可能在實現目標的同時表現出緊迫感;很少激勵他人並以強調競爭目的來取得成果。",
    2: "不太可能在实现目标的同时表现出紧迫感;很少激励他人并以强调竞争目的来取得成果。",
  },
  producerHighDescription: {
    0: "Has a sense of urgency to drive actions for stated goals; motivates others to deliver results with a competitive focus.",
    1: "對於實現既定的目標有緊迫感，會為目標採取實質行動;激勵他人以競爭為中心來實現目標。",
    2: "对于实现既定的目标有紧迫感，会为目标采取实质行动;激励他人以竞争为中心来实现目标。",
  },
  director: {
    0: "Director",
    1: "總監",
    2: "总监",
  },
  directorLowDescription: {
    0: "Rarely sets directions, planning, and objectives for others; unlikely to spend time and efforts in clarifying expectations and giving instructions.",
    1: "很少為他人設定方向、計劃和目 標;不太可能花費時間和精力來釐 清對工作的期望和發出清晰指示。",
    2: "很少为他人设定方向、计划和目 标;不太可能花费时间和精力来厘 清对工作的期望和发出清晰指示。",
  },
  directorHighDescription: {
    0: "Sets directions, planning, and objectives for others; clarifies expectations and gives instructions.",
    1: "為他人設定方向、計劃和目標;清 晰表達對工作的期望並給予指示。",
    2: "为他人设定方向、计划和目标;清 晰表达对工作的期望并给予指示。",
  },
  coordinator: {
    0: "Coordinator",
    1: "協調員",
    2: "协调员",
  },
  coordinatorLowDescription: {
    0: "Rarely attends to analyze and review technical information and reports; infrequently handles operational and administrative tasks.",
    1: "很少參與分析和審查技術資訊和報告; 很少處理營運和管理工作。",
    2: "很少参与分析和审查技术资讯和报告; 很少处理营运和管理工作。",
  },
  coordinatorHighDescription: {
    0: "Conducts rational analysis and reviews on technical information and reports; handles operational and administrative tasks.",
    1: "理性分析和審查技術資訊和報告； 處理營運和管理工作。",
    2: "理性分析和审查技术资讯和报告； 处理营运和管理工作。",
  },
  monitor: {
    0: "Monitor",
    1: "監督",
    2: "监督",
  },
  monitorLowDescription: {
    0: "Unlikely to get involved in setting systems, procedures, and guidelines; rarely attends to scrutinize rule and regulation compliance.",
    1: "不太可能參與制定系統、程序和指引;很少注意規則的審查和法規的合法性。",
    2: "不太可能参与制定系统、程序和指引;很少注意规则的审查和法规的合法性。",
  },
  monitorHighDescription: {
    0: "Establishes systems, procedures, and guidelines; scrutinizes rule and regulation compliance.",
    1: "建立系統、程序和指引;審查規則和確保法規的合法性。",
    2: "建立系统、程序和指引;审查规则和确保法规的合法性。",
  },
  facilitator: {
    0: "Facilitator",
    1: "促進者",
    2: "促进者",
  },
  facilitatorLowDescription: {
    0: "Rarely spends time and efforts in stakeholder management, building consensus, and resolving conflicts; infrequently concerns about cultivating team morale and promoting a collaborate culture.",
    1: "很少花時間和精力協助持份者建立共識、解決衝突;很少關注培養團隊士氣和促進協作文化。",
    2: "很少花时间和精力协助持份者建立共识、解决冲突;很少关注培养团队士气和促进协作文化。",
  },
  facilitatorHighDescription: {
    0: "Manages different stakeholders to build consensus and resolve conflicts; cultivates team morale and promotes a collaborative culture.",
    1: "讓不同持份者達成共識並解決衝突;培養團隊士氣並促進協作文化。",
    2: "让不同持份者达成共识并解决冲突;培养团队士气并促进协作文化。",
  },
  mentor: {
    0: "Mentor",
    1: "導師",
    2: "导师",
  },
  mentorLowDescription: {
    0: "Rarely gets involved in coaching and developing others; infrequently concerns about nurturing a caring work atmosphere.",
    1: "很少輔導和培養他人;很少著重培養一個關愛的工作氛圍。",
    2: "很少辅导和培养他人;很少着重培养一个关爱的工作氛围。",
  },
  mentorHighDescription: {
    0: "Coaches and develops others; nurtures a caring atmosphere in the workplace.",
    1: "輔導及培養他人;在工作單位營造出關愛的氛圍。",
    2: "辅导及培养他人;在工作单位营造出关爱的氛围。",
  },
  roleModel: {
    0: "Role Model",
    1: "好榜樣",
    2: "好榜样",
  },
  roleModelLowDescription: {
    0: "Rarely concerns about setting oneself as an example to others; Unlikely to see oneself role-modelling work ethics.",
    1: "很少致力為他人樹立榜樣;不太可能以自身樹立職業道德榜樣。",
    2: "很少致力为他人树立榜样;不太可能以自身树立职业道德榜样。",
  },
  roleModelHighDescription: {
    0: "Sets oneself as an example to others; role models work ethics when working with others.",
    1: "為他人樹立榜樣;成為與他人合作時的職業道德榜樣。",
    2: "为他人树立榜样;成为与他人合作时的职业道德榜样。",
  },
  impartialJudge: {
    0: "Impartial Judge",
    1: "公平法官",
    2: "公平法官",
  },
  impartialJudgeLowDescription: {
    0: "Rarely concerns about establishing trustful and respectful relationships with others; Unlikely to attend to allocate resources fairly and unselfishly.",
    1: "很少與他人建立信任和互相尊重的關係;不太可能公平和無私地分配資源。",
    2: "很少与他人建立信任和互相尊重的关系;不太可能公平和无私地分配资源。",
  },
  impartialJudgeHighDescription: {
    0: "Builds trustful and respectful relationships with others; allocates resources fairly and unselfishly.",
    1: "與他人建立信任和互相尊重的關係; 公平、無私地分配資源。",
    2: "与他人建立信任和互相尊重的关系; 公平、无私地分配资源。",
  },
};

export const footerLabels = {
  rights: {
    0: "© All Rights Reserved. Talent Link Global Limited",
    1: "版權所有©Talent Link Global Limited. 保留一切權利",
    2: "版权所有©Talent Link Global Limited. 保留一切权利",
  },
  endOfReport: {
    0: "- End of Report -",
    1: "~ 全報告完 ~",
    2: "~ 全报告完 ~",
  },
};
