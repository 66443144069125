import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@material-ui/core";
import { LanguageContext } from "../context/languageContext";
import { assessmentLabels } from "../config/commonLabels";

const useStyles = makeStyles({
  root: {
    maxWidth: 700,
    flexGrow: 1,
  },
});

export default function StepProgress(props) {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);

  return (
    <Box className={classes.root}>
      <MobileStepper
        variant="progress"
        steps={props.numSteps}
        position="static"
        activeStep={props.activeStep}
        nextButton={
          <Button
            size="small"
            onClick={props.handleNext}
            disabled={props.activeStep === props.numSteps - 1}
          >
            {assessmentLabels.next[language]}
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={props.handleBack}
            disabled={props.activeStep === 0}
          >
            <KeyboardArrowLeft />
            {assessmentLabels.back[language]}
          </Button>
        }
      />
    </Box>
  );
}
