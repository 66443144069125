import React, { useContext } from "react";
import { List, ListItem } from "@material-ui/core";
import { LanguageContext } from "../context/languageContext";

export default function ClbsLabel() {
  const { language } = useContext(LanguageContext);

  return (
    <div>
      {language === "0" && (
        <div>
          <h2>{"Psychological Assessment"}</h2>
          <p>
            The following 80 statements are some leadership behaviors at
            management level. Please use the rating scale below to indicate how
            frequently you have exhibited each behavior in the past 12 months.
          </p>
          <List>
            <ListItem>1 = Almost Never</ListItem>
            <ListItem>2 = Very infrequent</ListItem>
            <ListItem>3 = Infrequent</ListItem>
            <ListItem>4 = Sometimes</ListItem>
            <ListItem>5 = Frequent</ListItem>
            <ListItem>6 = Very Frequent</ListItem>
            <ListItem>7 = Almost Always</ListItem>
            <ListItem>0 = Not Applicable</ListItem>
          </List>
        </div>
      )}
      {language === "1" && (
        <div>
          <h2>{"心理測驗"}</h2>
          <p>
            以下列舉了一些管理級領袖的行為，合共80題。請使用下面的量表來顯示您於過去
            12 個月內表現每項行為的頻密程度：
          </p>
          <List>
            <ListItem>1 = 幾乎沒有</ListItem>
            <ListItem>2 = 非常少</ListItem>
            <ListItem>3 = 少</ListItem>
            <ListItem>4 = 間中</ListItem>
            <ListItem>5 = 頻密</ListItem>
            <ListItem>6 = 非常頻密</ListItem>
            <ListItem>7 = 幾乎常常</ListItem>
            <ListItem>0 = 不適用</ListItem>
          </List>
        </div>
      )}
      {language === "2" && (
        <div>
          <h2>{"心理测验"}</h2>
          <p>
            以下列举了一些管理级领袖的行为，合共80题。
            请使用下面的量表来显示您于过去 12 个月内表现每项行为的频密程度：
          </p>
          <List>
            <ListItem>1 = 几乎没有</ListItem>
            <ListItem>2 = 非常少</ListItem>
            <ListItem>3 = 少</ListItem>
            <ListItem>4 = 間中</ListItem>
            <ListItem>5 = 频密</ListItem>
            <ListItem>6 = 非常频密</ListItem>
            <ListItem>7 = 几乎常常</ListItem>
            <ListItem>0 = 不适用</ListItem>
          </List>
        </div>
      )}
    </div>
  );
}
