import React, { useContext } from "react";
import CircleSharpIcon from "@mui/icons-material/CircleSharp";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { LanguageContext } from "../context/languageContext";
import {
  headerLabels,
  aboutCLBSLabels,
  aboutReportLabels,
  reportLabels,
  footerLabels,
} from "../config/reportLabels";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: "295mm",
    width: "210mm",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  content: {
    paddingInline: "45px",
    fontFamily: "Segoe UI",
  },
  title: {
    fontSize: "32px",
    fontWeight: "bold",
    color: "#000000",
    textAlign: "center",
    margin: "0 auto",
    fontFamily: "Segoe UI",
  },
  section: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "5px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    marginRight: "10px",
  },
  aboutSection: {
    marginBottom: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  tableHeader: {
    backgroundColor: "#32849b",
    color: "#ffffff",
    fontWeight: "bold",
    padding: "8px",
    textAlign: "left",
    border: "1px solid white",
  },
  tableRow: {
    backgroundColor: "#b6dde7",
    border: "1px solid white",
  },
  tableCell: {
    padding: "8px",
    border: "1px solid white",
  },
  listItems: {
    paddingBlock: "5px",
  },

  // Report Styles
  headerCell: {
    fontSize: "14px",
    color: "#ffffff",
    backgroundColor: "#0f4535",
  },
  descriptionCell: {
    fontSize: "12px",
    paddingInline: "5px",
    paddingBlock: "2px",
    border: "1px solid black",
  },
  scoreHeaderCell: {
    textAlign: "center",
    width: "3%",
    border: "1px solid black",
  },

  // Footer
  footer: {
    color: "#888",
    display: "flex",
    textAlign: "center",
    paddingBlock: "35px",
    paddingInline: "65px",
    fontSize: "12px",
    marginRight: "auto",
  },
}));

const ReportPage = (props) => {
  return (
    <div id={`clbs-report-${props.email}`}>
      <Page1 userName={props.userName} userId={props.userId} />
      <Page2 score={props.score} />
    </div>
  );
};

const Page1 = (props) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const userName = props.userName;
  const userId = props.userId;
  const currentDate = new Date().toLocaleDateString();
  return (
    <div className={classes.root}>
      <h1 className={classes.title}>
        <p>{headerLabels.clbsTitle[language]}</p>
        <p>{headerLabels.report[language]}</p>
      </h1>
      <div className={classes.content}>
        <div className={classes.section}>
          <span className={classes.label}>
            {headerLabels.reportFor[language]}:
          </span>
          <span className={classes.label}>{userName}</span>
        </div>
        <div className={classes.section}>
          <span className={classes.label}>{headerLabels.id[language]}:</span>
          <span className={classes.label}>{userId}</span>
        </div>
        <div className={classes.section}>
          <span className={classes.label}>{headerLabels.date[language]}:</span>
          <span className={classes.label}>{currentDate}</span>
        </div>

        {/* About CLBS section */}
        <div className={classes.aboutSection}>
          <h2>{aboutCLBSLabels.title[language]}</h2>
          <p>{aboutCLBSLabels.description[language]}</p>
        </div>

        {/* Table */}
        <table className={classes.table}>
          <thead>
            <tr className={classes.tableRow}>
              <th className={classes.tableHeader}>
                {aboutCLBSLabels.leadershipQuality[language]}
              </th>
              <th className={classes.tableHeader}>
                {aboutCLBSLabels.roleBehaviour[language]}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.tableRow}>
              <td className={classes.tableCell} style={{ fontWeight: "bold" }}>
                {aboutCLBSLabels.leadingChange[language]}
              </td>
              <td className={classes.tableCell}>
                {aboutCLBSLabels.leadingChangeBehaviour[language]}
              </td>
            </tr>
            <tr className={classes.tableRow}>
              <td className={classes.tableCell} style={{ fontWeight: "bold" }}>
                {aboutCLBSLabels.producingResults[language]}
              </td>
              <td className={classes.tableCell}>
                {aboutCLBSLabels.producingResultsBehaviour[language]}
              </td>
            </tr>
            <tr className={classes.tableRow}>
              <td className={classes.tableCell} style={{ fontWeight: "bold" }}>
                {aboutCLBSLabels.managingProcess[language]}
              </td>
              <td className={classes.tableCell}>
                {aboutCLBSLabels.managingProcessBehaviour[language]}
              </td>
            </tr>
            <tr className={classes.tableRow}>
              <td className={classes.tableCell} style={{ fontWeight: "bold" }}>
                {aboutCLBSLabels.relatingToPeople[language]}
              </td>
              <td className={classes.tableCell}>
                {aboutCLBSLabels.relatingToPeopleBehaviour[language]}
              </td>
            </tr>
            <tr className={classes.tableRow}>
              <td className={classes.tableCell} style={{ fontWeight: "bold" }}>
                {aboutCLBSLabels.exhibitingMoralBehaviour[language]}
              </td>
              <td className={classes.tableCell}>
                {aboutCLBSLabels.exhibitingMoralBehaviourBehaviour[language]}
              </td>
            </tr>
          </tbody>
        </table>

        <div className={classes.aboutSection}>
          <h2>{aboutReportLabels.about[language]}</h2>
          <ul>
            <li className={classes.listItems}>
              {aboutReportLabels.pt1[language]}
            </li>
            <li className={classes.listItems}>
              {aboutReportLabels.pt2[language]}
            </li>
            <li className={classes.listItems}>
              {aboutReportLabels.pt3[language]}
            </li>
            <li className={classes.listItems}>
              {aboutReportLabels.pt4[language]}
            </li>
            <li className={classes.listItems}>
              {aboutReportLabels.pt5[language]}
            </li>
            <li className={classes.listItems}>
              {aboutReportLabels.pt6[language]}
            </li>
          </ul>
        </div>
      </div>
      <Footer pageNumber={1} maxPageNumber={2} />
    </div>
  );
};

const Page2 = (props) => {
  const classes = useStyles();
  const score = props.score;
  const { language } = useContext(LanguageContext);

  return (
    <div className={classes.root}>
      <h1
        className={classes.title}
        style={{ fontSize: "24px", paddingTop: "30px", paddingBottom: "10px" }}
      >
        {reportLabels.title[language]}
      </h1>
      <div className={classes.content}>
        <table
          style={{ border: "1px solid black", borderCollapse: "collapse" }}
        >
          <thead>
            <tr className={classes.headerCell}>
              <th style={{ border: "1px solid black", width: "15%" }}>
                {reportLabels.behaviour[language]}
              </th>
              <th style={{ border: "1px solid black" }}>
                {reportLabels.lowFreq[language]}
              </th>
              <th style={{ border: "1px solid black" }} colSpan="10">
                {reportLabels.stenScore[language]}
              </th>
              <th style={{ border: "1px solid black" }}>
                {reportLabels.highFreq[language]}
              </th>
            </tr>
          </thead>
          <tbody>
            <HeaderCell
              title={aboutCLBSLabels.leadingChange[language]}
              score={score["leading_change"]}
            />
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.innovator[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.innovatorLowDescription[language]}
              </td>
              <ScoreCell score={score["innovator"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.innovatorHighDescription[language]}
              </td>
            </tr>
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.negotiator[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.negotiatorLowDescription[language]}
              </td>
              <ScoreCell score={score["negotiator"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.negotiatorHighDescription[language]}
              </td>
            </tr>

            <HeaderCell
              title={aboutCLBSLabels.producingResults[language]}
              score={score["produce_results"]}
            />
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.producer[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.producerLowDescription[language]}
              </td>
              <ScoreCell score={score["producer"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.producerHighDescription[language]}{" "}
              </td>
            </tr>
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.director[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.directorLowDescription[language]}
              </td>
              <ScoreCell score={score["director"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.directorHighDescription[language]}
              </td>
            </tr>

            <HeaderCell
              title={aboutCLBSLabels.managingProcess[language]}
              score={score["managing_process"]}
            />
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.coordinator[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.coordinatorLowDescription[language]}
              </td>
              <ScoreCell score={score["coordinator"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.coordinatorHighDescription[language]}
              </td>
            </tr>
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.monitor[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.monitorLowDescription[language]}
              </td>
              <ScoreCell score={score["monitor"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.monitorHighDescription[language]}
              </td>
            </tr>

            <HeaderCell
              title={aboutCLBSLabels.relatingToPeople[language]}
              score={score["relating_to_people"]}
            />
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.facilitator[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.facilitatorLowDescription[language]}{" "}
              </td>
              <ScoreCell score={score["facilitator"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.facilitatorHighDescription[language]}{" "}
              </td>
            </tr>
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.mentor[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.mentorLowDescription[language]}{" "}
              </td>
              <ScoreCell score={score["mentor"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.mentorHighDescription[language]}{" "}
              </td>
            </tr>

            <HeaderCell
              title={aboutCLBSLabels.exhibitingMoralBehaviour[language]}
              score={score["exihibiting_moral_behaviors"]}
            />
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.roleModel[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.roleModelLowDescription[language]}
              </td>
              <ScoreCell score={score["role_model"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.roleModelHighDescription[language]}
              </td>
            </tr>
            <tr>
              <td
                className={classes.descriptionCell}
                style={{ fontWeight: "bold" }}
              >
                {reportLabels.impartialJudge[language]}
              </td>
              <td className={classes.descriptionCell}>
                {reportLabels.impartialJudgeLowDescription[language]}
              </td>
              <ScoreCell score={score["impartial_judge"]} />
              <td className={classes.descriptionCell}>
                {reportLabels.impartialJudgeHighDescription[language]}
              </td>
            </tr>
          </tbody>
        </table>
        <h3 style={{ textAlign: "center" }}>
          {footerLabels.endOfReport[language]}
        </h3>
      </div>
      <Footer pageNumber={2} maxPageNumber={2} />
    </div>
  );
};

const HeaderCell = (props) => {
  const scores = Array.from({ length: 10 }, (_, index) => index + 1);
  const classes = useStyles();
  return (
    <tr className={classes.headerCell}>
      <td className={classes.descriptionCell} style={{ fontWeight: "bold" }}>
        {props.title}
      </td>
      <td style={{ border: "1px solid black" }}></td>
      {scores.map((score, idx) =>
        score === props.score ? (
          <td
            key={idx}
            className={classes.scoreHeaderCell}
            style={{ backgroundColor: "#FB8C00" }}
          >
            {score}
          </td>
        ) : (
          <td key={idx} className={classes.scoreHeaderCell}>
            {score}
          </td>
        )
      )}
      <td style={{ border: "1px solid black" }}></td>
    </tr>
  );
};

const ScoreCell = (props) => {
  const colors = [
    "#f2f2f2",
    "#e9eceb",
    "#dee2e4",
    "#d8dedf",
    "#d1d8dc",
    "#cdd3d8",
    "#c6ccd0",
    "#c3c9cd",
    "#b8bec2",
    "#adb2b9",
  ];
  return colors.map((color, idx) => (
    <td
      style={{
        backgroundColor: color,
        border: "1px solid black",
        textAlign: "center",
      }}
      key={idx}
    >
      {props.score === idx + 1 && <CircleSharpIcon fontSize="inherit" />}
    </td>
  ));
};

const Footer = (props) => {
  const { language } = useContext(LanguageContext);

  return (
    <Grid
      style={{
        marginTop: "auto",
        marginBottom: "30px",
        marginInline: "45px",
        fontSize: "12px",
      }}
      container
      spacing={2}
    >
      <Grid item style={{ textAlign: "left", width: "60%", color: "#888" }}>
        <p>{footerLabels.rights[language]}</p>
      </Grid>
      <Grid item style={{ textAlign: "right", width: "20%" }}>
        {language === "0" && (
          <p>
            Page <span style={{ fontWeight: "bold" }}>{props.pageNumber}</span>{" "}
            of <span style={{ fontWeight: "bold" }}>{props.maxPageNumber}</span>
          </p>
        )}
        {language === "1" && (
          <p>
            第{props.pageNumber}頁 (共{props.maxPageNumber}頁)
          </p>
        )}
        {language === "2" && (
          <p>
            第{props.pageNumber}页 (共{props.maxPageNumber}页)
          </p>
        )}
      </Grid>
    </Grid>
  );
};

export default ReportPage;
